<template>
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.396531 1.19836C0.720813 0.850547 1.24658 0.850547 1.57086 1.19836L5.96594 5.91234L10.361 1.19836C10.6853 0.850547 11.2111 0.850547 11.5354 1.19836C11.8596 1.54617 11.8596 2.11008 11.5354 2.45789L6.55311 7.80164C6.22883 8.14945 5.70306 8.14945 5.37878 7.80164L0.396531 2.45789C0.0722499 2.11008 0.0722499 1.54617 0.396531 1.19836Z"
      fill="currentColor"
    />
  </svg>
</template>
